.fade-enter div, .fade-enter ~ tr:nth-child(1) div, .fade-enter ~ tr:nth-child(2) div {
  overflow-y: hidden;
  max-height: 0;
}

.fade-enter-active div, .fade-enter-active ~ tr:nth-child(1) div, .fade-enter-active ~ tr:nth-child(2) div {
  max-height: 50px;
  transition: all 500ms ease-in;
}

.fade-exit div, .fade-exit ~ tr:nth-child(1) div, .fade-exit ~ tr:nth-child(2) div {
  max-height: 50px;
}

.fade-exit-active div, .fade-exit-active ~ tr:nth-child(1) div, .fade-exit-active ~ tr:nth-child(2) div {
  max-height: 0;
  overflow-y: hidden;
  transition: all 500ms ease-in;
}